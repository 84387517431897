import React from 'react';
import withFirebaseAuth from 'react-auth-firebase';
import firebase from './firebase';
import Home from './Home';
import './style.css';

const App = (props: { signInWithGoogle; user; error; signOut }) => {
  if (
    props.user &&
    !(
      props.user &&
      props.user.providerData[0].providerId === 'google.com' &&
      props.user.email.match(/@tokyomixcurry.com$/)
    )
  ) {
    props.signOut();
    return <></>;
  }

  return props.user ? (
    <Home error={props.error} signOut={props.signOut} />
  ) : (
    <div id="form-signin-container">
      <form className="form-signin">
        <div className="text-center mb-4">
          <img className="mb-4 rounded" src="/images/logo.png" alt="logo" width="64" height="64" />
          <h1 className="h3 mb-3 font-weight-normal">TMC Maps App</h1>
        </div>

        {props.error ? (
          <div className="alert alert-danger" role="alert">
            {props.error.message}
          </div>
        ) : null}

        {!props.user && (
          <div>
            <button type="button" className="btn btn-lg btn-secondary btn-block" onClick={props.signInWithGoogle}>
              Googleアカウントでログイン
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

const authConfig = {
  email: {
    verifyOnSignup: false,
    saveUserInDatabase: true,
  },
  google: {
    // redirect: true, // Opens a pop up by default
    returnAccessToken: true,
    saveUserInDatabase: true,
  },
};

// export default App;
export default withFirebaseAuth(App, firebase, authConfig);
