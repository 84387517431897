import React from 'react';
import Orders from './Orders';

const Home = (props: { signOut; error }) => (
  <div id="orders">
    <div className="container">
      <header className="row my-5">
        <div className="col text-center">TMCマップ</div>
      </header>

      <Orders />
      <footer className="row justify-content-center">
        <div className="col-lg-9 col-sm-12">
          <button type="button" className="btn btn-link" onClick={props.signOut}>
            Sign Out
          </button>
          {props.error ? <h1>{props.error.message}</h1> : null}
        </div>
      </footer>
    </div>
  </div>
);

export default Home;
