import React from 'react';
import { render } from 'react-dom';
import { QueryParamProvider } from 'use-query-params';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

// adhoc: Squareのcallbackでadmin=1が削除されてしまうので追加
if (window.location.href.match(/com.squareup.pos/)) {
  const pageUrl = `${window.location.href}&admin=1`;
  window.history.pushState('', '', pageUrl);
}

// 登録されてしまったservice-workerを削除
if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}



const Index = () => (
  <BrowserRouter>
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
    </QueryParamProvider>
  </BrowserRouter>
);

render(<Index />, document.getElementById('root'));
