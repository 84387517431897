import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import GoogleMapReact from 'google-map-react';
import { useQueryParam, StringParam } from 'use-query-params';
import moment from 'moment-timezone';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import firebase from './firebase';

function Orders() {
  const [queryPlace] = useQueryParam('q', StringParam);
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [waitingMessage, setWaitingMessage] = useState<string>();
  const [messageError, setMessageError] = useState('');
  const [shops, setShops] = useState<any>();
  const [customers, setCustomers] = useState<Array<any>>();
  const [mapCounter, setMapCounter] = useState(0);

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('shops')
      .orderBy('order')
      .onSnapshot((snap) => {
        const records = {};
        snap.forEach((docSnapshot) => {
          records[docSnapshot.id] = docSnapshot.data();
        });

        setShops(records);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, []);

  const handleApiLoaded = async (map, maps) => {
    const input = document.getElementById('pac-input');
    // const searchBox = new google.maps.places.SearchBox(input);
    map.controls[maps.ControlPosition.TOP_LEFT].push(input);

    const query = await firebase.firestore().collection('shops').where('enabled', '==', true).get();
    for (const doc of query.docs) {
      const shopData = doc.data();

      if (!shopData.batch_delivery) {
        const shopLocation = {
          lat: shopData.location.latitude,
          lng: shopData.location.longitude,
        };

        new maps.Marker({
          position: shopLocation,
          map,
          icon: {
            path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
            fillColor: '#B3CF37',
            fillOpacity: 0.6,
            strokeWeight: 2,
            scale: 0.4,
          },
          label: 'T',
        });

        const radius = 250;
        const color = '#FF0000';

        new maps.Circle({
          strokeColor: color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: 0.35,
          map,
          center: {
            lat: shopData.location.latitude,
            lng: shopData.location.longitude,
          },
          radius,
        });
      }
    }

    // https://sites.google.com/site/gmapsdevelopment/

    const icons = {
      pickup: {
        url: 'https://maps.google.com/mapfiles/ms/micons/man.png',
      },
      delivery: {
        url: 'https://maps.google.com/mapfiles/ms/micons/cycling.png',
      },
    };

    if (queryPlace) {
      const request = {
        query: queryPlace,
        fields: ['name', 'geometry'],
      };

      const searchService = new maps.places.PlacesService(map);

      searchService.findPlaceFromQuery(request, (results, status) => {
        if (status === maps.places.PlacesServiceStatus.OK) {
          const place = results[0];
          // eslint-disable-next-line no-new
          new maps.Marker({
            map,
            position: place.geometry.location,
            icon: {
              url: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
              scaledSize: new maps.Size(48, 48),
            },
          });

          map.setCenter(results[0].geometry.location);
        }
      });
    }

    if (customers) {
      for (const customer of customers) {
        let location;
        let icon;

        if (customer.delivery_location?.latitude) {
          location = {
            lat: customer.delivery_location.latitude,
            lng: customer.delivery_location.longitude,
          };
          icon = icons.delivery;
        } else {
          location = {
            lat: customer.location.latitude,
            lng: customer.location.longitude,
          };
          icon = icons.pickup;
        }

        if (location) {
          // eslint-disable-next-line no-new
          const marker = new maps.Marker({
            position: location,
            map,
            icon,
          });

          marker.addListener('mouseover', () => console.log(customer));
        }
      }
    }
  };

  const submit = (argData) => {
    const data = { ...argData };

    const apiEndPoint = `${process.env.REACT_APP_api_server}/customers/search_by_segment`;

    const auth = firebase.auth();
    setWaitingMessage('検索中');
    setLoading(true);

    if (data.segment.joined_before) {
      data.segment.joined_before = moment.tz(data.segment.joined_before, 'Asia/Tokyo').unix();
    } else {
      data.segment.joined_before = null;
    }

    if (data.segment.joined_after) {
      data.segment.joined_after = moment.tz(data.segment.joined_after, 'Asia/Tokyo').unix();
    } else {
      data.segment.joined_after = null;
    }

    data.segment.only_pass_customers = !!data.segment.only_pass_customers;
    data.segment.exclude_pass_customers = !!data.segment.exclude_pass_customers;

    data.segment.exclude_ordering = !!data.segment.exclude_ordering;

    auth.currentUser!.getIdToken().then((token) => {
      fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          const responseJson = await response.json();
          if (response.status === 200 || response.status === 201) {
            setCustomers(responseJson.customers);
            setMessageError('');
            setMapCounter((prevCount) => prevCount + 1);
          } else {
            setMessageError(responseJson.error.message);
          }
          setLoading(false);
        })
        .catch((error) => {
          setMessageError('検索に失敗しました');
          setLoading(false);
        });
    });
  };

  return (
    <>
      <div className="row container">
        <div className="col-4">
          {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}

          {shops ? (
            <form id="message-form" onSubmit={handleSubmit(submit)}>
              <h3>セグメント</h3>

              <div className="form-group row">
                <div className="col-sm-8">
                  <div className="form-group row">
                    <label htmlFor="selectShop" className="col-sm-4 col-form-label">
                      店舗
                    </label>
                    <div className="col-sm-6">
                      <select size={10} multiple {...register('segment.shop_ids', {})}>
                        {shops
                          ? Object.keys(shops).map((shopId) => (
                              <option key={shopId} value={shopId}>
                                {shops[shopId].short_name}
                              </option>
                            ))
                          : ''}
                      </select>
                      <small className="form-text text-muted">最後の注文がこの店舗</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="inputDistance" className="col-sm-4 col-form-label">
                      購入地点の店舗からの距離(メートル) 内側
                    </label>
                    <div className="col-sm-6">
                      <input type="number" id="inputDistance" {...register('segment.distance', {})} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="inputDistanceOutside" className="col-sm-4 col-form-label">
                      購入地点の店舗からの距離(メートル) 外側
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="number"
                        id="inputDistanceOutside"
                        {...register('segment.distance_outside', {})}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="inputCouponUsed" className="col-sm-4 col-form-label">
                      このクーポンを使ったことがある人
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="inputCouponUsed"
                        size={60}
                        {...register('segment.coupon_used', {})}
                        placeholder="クーポンコードをカンマ区切り"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="inputCouponNotUsed" className="col-sm-4 col-form-label">
                      このクーポンを使ったことがない人
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="inputCouponNotUsed"
                        size={60}
                        {...register('segment.coupon_not_used', {})}
                        placeholder="クーポンコードをカンマ区切り"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="inputCurriesDone" className="col-sm-4 col-form-label">
                      N回カレーを食べたことがある人。
                      <br />
                      例: 1 (1回) / &gt;=2 (2回以上) / &lt;3 (3回未満) / &gt;=2, &lt;5 (2回以上5回未満)
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="inputCurriesDone"
                        {...register('segment.number_of_curries_done', {})}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="inputDaysAfterLastVisit" className="col-sm-4 col-form-label">
                      N日間以上カレーを食べていない人。(昨日食べた人が1)
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="inputDaysAfterLastVisit"
                        {...register('segment.days_after_last_visit', {})}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="inputDaysAfterLastVisit" className="col-sm-4 col-form-label">
                      N日間以内にカレーを食べた人 (昨日食べた人が1)
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="inputDaysRecentVisit"
                        {...register('segment.days_recent_visit', {})}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="inputJoinedDatetimeBefore" className="col-sm-4 col-form-label">
                      この日時以前にアプリダウンロードした人
                    </label>
                    <div className="col-sm-6">
                      <input type="datetime-local" {...register('segment.joined_before', {})} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="inputJoinedDatetimeAfter" className="col-sm-4 col-form-label">
                      この日時以降にアプリダウンロードした人
                    </label>
                    <div className="col-sm-6">
                      <input type="datetime-local" {...register('segment.joined_after', {})} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="inputOnlyPassCustomers" className="col-sm-4 col-form-label">
                      パスユーザーのみ
                    </label>
                    <div className="col-sm-6">
                      <input type="checkbox" {...register('segment.only_pass_customers', {})} value="1" />
                      <br />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="inputExcludePassCustomers" className="col-sm-4 col-form-label">
                      パスユーザーを除外
                    </label>
                    <div className="col-sm-6">
                      <input type="checkbox" {...register('segment.exclude_pass_customers', {})} value="1" />
                      <br />
                    </div>
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label htmlFor="inputCouponUsed" className="col-sm-4 col-form-label">
                      グループ指定 (A/Bテスト用){' '}
                    </label>
                    <div className="col-sm-6">
                      Group 0{' '}
                      <input
                        type="checkbox"
                        defaultChecked
                        id="inputGroups"
                        {...register('segment.groups[0]', {})}
                        value="0"
                      />
                      <br />
                      Group 1{' '}
                      <input
                        type="checkbox"
                        defaultChecked
                        id="inputGroups"
                        {...register('segment.groups[1]', {})}
                        value="1"
                      />
                      <br />
                      Group 2{' '}
                      <input
                        type="checkbox"
                        defaultChecked
                        id="inputGroups"
                        {...register('segment.groups[2]', {})}
                        value="2"
                      />
                      <br />
                      Group 3{' '}
                      <input
                        type="checkbox"
                        defaultChecked
                        id="inputGroups"
                        {...register('segment.groups[3]', {})}
                        value="3"
                      />
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <input type="submit" className="btn btn-primary" value="検索" />
            </form>
          ) : null}
        </div>
        <div className="col-8">
          <div style={{ height: '80vh', width: '100%' }}>
            <GoogleMapReact
              key={`map-${mapCounter}`}
              bootstrapURLKeys={{
                key: 'AIzaSyDBHnjopFX1AG3i7MvAOCDoeUHuuXNGXwk',
                libraries: ['drawing', 'places'].join(','),
              }}
              defaultCenter={{ lat: 35.675903, lng: 139.7413625 }}
              defaultZoom={13}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            />
          </div>
        </div>
      </div>
      <WindmillSpinnerOverlay loading={loading} message={waitingMessage} />
    </>
  );
}

export default Orders;
